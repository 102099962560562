import { graphql, Link } from "gatsby";
import * as React from "react";
import { Head } from "../components/blog/Head";
import { HTMLContent } from "../components/common/Content";
import Layout from "../components/layout/Layout";
// import { Disqus, CommentCount } from "gatsby-plugin-disqus";
// import { getDisqusConfig } from "../components/utils/Blog";
import { Author } from "../components/blog/Author";
import highlightCode from "../components/utils/HighlightCode";
import moment from "moment";

// props
interface Props {
  data: {
    post: {
      id: string;
      html: string;
      frontmatter: {
        title: string;
        email: string;
        author: string;
        category: string;
        date: string;
      };
    };
  };
}

// markup
const BlogTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode();
  });
  const blogInfo = props.data.post;
  const date = moment(blogInfo.frontmatter.date, "M/D/YYYY");
  // const author = props.data.author;
  // const disqusConfig = getDisqusConfig(props.data.post);

  return (
    <Layout
      className="blog"
      seo={{
        title: blogInfo.frontmatter.title,
      }}
    >
      <div className="container mb-6">
        <Head />
        <div className="columns content pt-5 pb-6">
          <div className="column is-9 pr-6">
            <div className="blog-post mb-4" style={{ borderTop: 0 }}>
              <h1
                style={{ lineHeight: "3rem" }}
                className="has-text-weight-semibold is-size-3"
              >
                {blogInfo.frontmatter.title}
              </h1>
              <div className="meta">
                {blogInfo.frontmatter.category}
                {" | "}
                {blogInfo.frontmatter.author || "n/a"}
                {" | "}
                {date.format("MMM DD, YYYY")}
                {/* <Author author={author} /> */}
                {/* {` | `}
                <Link to={`#disqus_thread`}>
                  <CommentCount config={disqusConfig} placeholder={"..."} />
                </Link> */}
              </div>
            </div>
            <HTMLContent
              content={blogInfo?.html}
              className={"markdown"}
            ></HTMLContent>
            {/* <Disqus config={disqusConfig} />*/}
          </div>
          {/* <div className="column is-3">
            <div className="has-background-light px-5 py-5 blog-sidebar">
              <h2 className="is-size-4 has-text-weight-bold">
                Get the Project Reactor newsletter
              </h2>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default BlogTemplate;

// graphql Blog query
export const pageQuery = graphql`
  query BlogPage($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        email
        author
        category
        slug
        date
      }
    }
  }
`;
